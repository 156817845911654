<template>
  <section class="page-header">
    <div class="container p-2">
      <swiper class="swiper-autoplay" :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
        <swiper-slide v-for="data in swiperData" :key="data.img">
          <b-img src="https://myskill.id/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmain.3d8b87dd.png&w=1920&q=75" class="img-fluid custom-swiper-img"
            style="width: 85%; height: 370px; object-fit: cover; border-radius: 15px;" />
        </swiper-slide>
      </swiper>
    </div>

    <h4 class="text-center mt-2">
      Raih Sukses Akademis Bersama Kami
    </h4>

    <div class="container" style="padding-left: 10vw; padding-right: 10vw; margin-top: 3rem; margin-bottom: 1.5rem;">
      <div class="row g-4">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="card custom-card">
            <div class="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-graduation-cap custom-icon mt-2" style="color: #003366;"></i>
            </div>
            <div class="mt-3 text-center">
              <h5 class="text-muted">
                <i class="d-block text-black font-weight-bold mb-1"><b>Lebih dari 100+</b></i>
                <div style="font-size: 1.1rem; font-weight: normal;">Siswa Menghadapi Ujian dengan Percaya Diri</div>
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
          <div class="card custom-card">
            <div class="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-book custom-icon mt-2" style="color: #6F4F28;"></i>
            </div>
            <div class="mt-3 text-center">
              <h5 class="text-muted">
                <i class="d-block text-black font-weight-bold mb-1"><b>Persiapan Lengkap</b></i>
                <div style="font-size: 1.1rem; font-weight: normal;">Untuk UH, PTS, PAS, US, SNBP, SNBT, dan Kedinasan
                </div>
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
          <div class="card custom-card">
            <div class="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-star custom-icon mt-2" style="color: #FFD700;"></i>
            </div>
            <div class="mt-3 text-center">
              <h5 class="text-muted">
                <i class="d-block text-black font-weight-bold mb-1"><b>Metode Terbukti</b></i>
                <div style="font-size: 1.1rem; font-weight: normal;">Tutor Profesional, Fasilitas Terbaik, dan Biaya
                  Terjangkau</div>
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3" style="margin-bottom: 90px;">
          <div class="card custom-card">
            <div class="d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-arrows-down-to-people custom-icon mt-2" style="color: #FF0000;"></i>
            </div>
            <div class="mt-3 text-center">
              <h5 class="text-muted">
                <i class="d-block text-black font-weight-bold mb-1"><b>Lebih dari 90%</b></i>
                <div style="font-size: 1.1rem; font-weight: normal;">Bergabunglah dengan kami dan capai tujuan akademis
                  Anda</div>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
  import {
    BCard,
    BImg,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
    BButton,
    BNav,
  } from "bootstrap-vue";
  import Ripple from 'vue-ripple-directive';
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  import {
    codeCenterSlides
  } from './code'
  export default {
    components: {
      BCard,
      BNav,
      BTabs,
      BButton,
      BNavItem,
      BNavbar,
      BNavbarToggle,
      BCollapse,
      BImg,
      Swiper,
      SwiperSlide,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        img: require("@/assets/images/elp/ELP.png"),
        panah: require("@/assets/images/elp/panah melengkung.png"),
        swiperOptions: {
          spaceBetween: 30,
          centeredSlides: true,
          autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },

        /* eslint-disable global-require */
        swiperData: [{
            img: 'https://www.mditack.co.id/wp-content/uploads/2020/09/Employee_Training_and_Development.jpg'
          },
          {
            img: 'https://www.marjon.ac.uk/media/2018-website-images-gdpr/courses/teacher-training/2018_Teacher_training_Banner.jpg'
          },
          {
            img: 'https://pix11.com/wp-content/uploads/sites/25/2020/07/teachersupply-2.jpg'
          },
        ],
      };
    },
    props: {
      headerData: {
        type: Object,
        default: () => {},
      },


    },
  };
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

  * {
    font-family: "Open Sans", sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0;
    font-size: 17px;
    color: #8d8f90;
    font-weight: 400;
    line-height: 32px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .custom-card {
    min-height: 130px;
    max-width: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0;
  }

  .custom-icon {
    font-size: 50px;
  }

  @media (min-width: 576px) {
    .container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (min-width: 768px) {
    .container {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  @media (min-width: 992px) {
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #25292a;
    margin: 0 0 10px 0;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1;
  }

  .thin-text {
    font-weight: 300;
    color: #333;
  }

  h5 span {
    font-weight: bold;
  }

  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 28px;
    line-height: 38px;
  }

  h3 {
    font-size: 22px;
    line-height: 32px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 17px;
  }

  h6 {
    font-size: 12px;
  }

  /* Paragraphs */
  p {
    margin: 0 0 20px;
    line-height: 1.7;
  }

  p:last-child {
    margin: 0;
  }

  /* Lists */
  ul,
  ol {
    margin: 0;
    padding: 0;
  }

  /* Links */
  a {
    text-decoration: none;
    color: #8d8f90;
    transition: all 0.3s;
  }

  a:focus,
  a:hover {
    text-decoration: none;
    color: #f85759;
  }

  .page-header {
    position: relative;
    background: linear-gradient(to bottom, #cce8ed, #ffffff);
    background-size: cover;
    overflow: hidden;
  }

  /* Pseudo-elemen untuk border-radius di bawah background */
  .page-header::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background: inherit;
    border-radius: 0 0 30px 30px;
    z-index: -1;
  }

  .page-caption {
    display: flex;
    /* Menggunakan Flexbox untuk penataan */
    align-items: center;
    /* Menyelaraskan item secara vertikal di tengah */
    justify-content: space-between;
    /* Memberikan jarak antara teks dan gambar */
    padding-top: 170px;
    padding-bottom: 174px;
    padding-left: 30px;
    /* Menambahkan padding kiri jika diperlukan */
  }

  .page-profile {
    font-size: 46px;
    line-height: 1;
    color: #fff;
    font-weight: 600;
    text-align: left;
    /* Teks diatur ke kiri */
    margin: 0;
    /* Menghapus margin untuk penyelarasan */
  }

  .page-subtext {
    font-size: 16px;
    /* Ukuran font lebih kecil */
    color: #fff;
    /* Warna putih agar konsisten */
    margin-left: 0px;
    /* Sesuaikan margin kiri agar sejajar */
    margin-top: 10px;
    /* Jarak sedikit di bawah teks utama */
  }

  .page-caption img {
    margin-left: auto;
  }

  .card-section {
    position: relative;
    bottom: 60px;
  }

  .card-block {
    padding: 80px;
  }

  .section-title {
    margin-bottom: 60px;
  }

  /* Header Styles */
  .header-container {
    padding: 0 50px;
    margin-left: 30px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 500px;
    color: white;
  }

  .header-title {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: black;
  }

  .header-image {
    display: flex;
    align-items: center;
  }

  .card {
    border: none;
    border-radius: 10px;
  }

  .card .icon {
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 39px;
  }

  .card .heading {
    font-size: 1rem;
    /* Menyesuaikan ukuran font sesuai kebutuhan */
  }
</style>