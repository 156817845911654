<template>
  <div>
    <h4 class="text-center" style="margin-bottom: 50px;">
      Berbagai program kami
    </h4>

    <div class="container">
      <div class="row align-items-center mb-5">
        <!-- Image Column -->
        <div class="col-md-6 d-flex image-side justify-content-center fade-in">
          <img src="https://pngfre.com/wp-content/uploads/book-82-300x222.png" alt="Sample Image" class="custom-image">
        </div>
        <!-- Text Column -->
        <div class="col-md-6 d-flex text-side fade-in">
          <div>
            <h3>Paket Bimbel Lengkap: Sertifikat & Video Pembelajaran</h3>
            <p class="text-black">✅ Sertifikat Penyelesaian: Dapatkan sertifikat resmi setelah menyelesaikan setiap
              paket bimbel sebagai bukti pencapaian dan kompetensi kamu.</p>
            <p class="text-black">✅ Akses Video Pembelajaran Lengkap: Setiap paket mencakup video pembelajaran lengkap
              yang dapat diakses kapan saja, memastikan kamu mendapatkan materi secara menyeluruh.</p>
            <p class="text-black">✅ Pembayaran Sekali Bayar Bayar sekali untuk akses penuh dan seumur hidup ke seluruh
              materi dalam paket, tanpa biaya tambahan di masa depan.</p>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <!-- Text Column -->
        <div class="col-md-6 d-flex text-side fade-in">
          <div>
            <h2>E-book</h2>
            <p class="text-black">✅ Belajar Fleksibel Akses berbagai materi belajar termasuk video, bahan bacaan, dan
              latihan praktis kapan saja dan di mana saja.</p>
            <p class="text-black">✅ Struktur Pembelajaran Bertahap: Konten disusun dari level Dasar hingga Lanjut,
              memastikan kamu memahami setiap konsep dengan baik.</p>
            <p class="text-black">✅ Akses Lifetime: Nikmati akses seumur hidup ke e-book, termasuk semua pembaruan dan
              materi tambahan di masa depan.</p>
          </div>
        </div>
        <!-- Image Column -->
        <div class="col-md-6 d-flex image-side justify-content-center fade-in">
          <img src="https://cdn-icons-png.flaticon.com/256/9913/9913434.png" alt="Sample Image" class="custom-image">
        </div>
      </div>

      <div class="row align-items-center mb-5 mt-5">
        <!-- Image Column -->
        <div class="col-md-6 d-flex image-side justify-content-center fade-in">
          <img
            src="https://png.pngtree.com/png-vector/20220708/ourmid/pngtree-rectangular-green-chalkboard-png-image_5683105.png"
            alt="Sample Image" class="custom-image">
        </div>
        <!-- Text Column -->
        <div class="col-md-6 d-flex text-side fade-in">
          <div>
            <h2>Mading</h2>
            <p class="text-black">✅ Belajar Kapan Saja Nikmati fleksibilitas dalam belajar dengan artikel yang bisa
              dibaca kapan saja, tanpa batasan waktu.</p>
            <p class="text-black">✅ Update Rutin Dapatkan konten terbaru dan terupdate secara berkala, sehingga kamu
              selalu mendapatkan informasi terkini.</p>
            <p class="text-black">✅ Akses Gratis Semua materi di mading tersedia tanpa biaya, memungkinkan kamu untuk
              belajar tanpa beban finansial.</p>
          </div>
        </div>
      </div>
    </div>


    <section id="card-images">
      <b-overlay :show="loading">
        <b-col>
          <h4 class="text-center" style="margin-top: 120px;">
            Beragam Paket Bimbel & Try Out untuk Mencapai Tujuanmu
          </h4>

          <div class="py-4">
            <!-- Reduced vertical padding -->
            <div class="container">
              <div class="row row-cols-1 row-cols-md-4 g-3">
                <!-- Adjusted gap with g-3 -->

                <div class="col mb-4" v-for="(item, i) in data" :key="i" @click="detail(item)">
                  <!-- Removed container class -->

                  <div class="card mx-auto shadow-lg rounded-3"
                    style="width: 100%; max-width: 20rem; transition: 0.3s;">
                    <div class="position-relative overflow-hidden rounded-top-3">
                      <img :src="`https://api-ecourse.digma.id/${item.thumbnail}`" class="card-img-top"
                        alt="City Lights In Newyork" style="height: 200px; object-fit: cover" />
                    </div>
                    <div class="card-body">
                      <!-- Duration and Category -->
                      <div class="d-flex mb-2" style="font-weight:bold; font-size: 14px;">
                        <span class="text-black">{{ item.name }}</span>
                      </div>

                      <div class="d-flex align-items-center mb-2">
                        <i class="fa-regular fa-user text-black"></i>
                        <span class="text-black ms-1" style="margin-left:8px;">150</span>
                        <i class="fa-regular fa-clock text-black" style="margin-left:20px;"></i>
                        <span class="text-black" style="margin-left:8px;">40m</span>
                      </div>
                      <hr>
                      <!-- Discount and Pricing -->
                      <div class="d-flex align-items-center">
                        <span class="text-black me-2" style="font-weight:bold;">Rp
                          {{ item.price.toLocaleString('id-ID') }}</span>
                        <span class="ml-auto">
                          <button type="button" class="btn btn-outline-primary btn-sm">Beli</button>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
              <center>
                <b-button variant="outline-primary" class="mb-2" :to="{ name: 'paket-selengkapnya' }">
                  Selengkapnya
                </b-button>
              </center>
            </div>
          </div>

        </b-col>
      </b-overlay>
    </section>
  </div>
</template>

<script>
  import {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
  } from "bootstrap-vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    components: {
      BOverlay,
      BButton,
      BRow,
      BCol,
      BCard,
      BCardText,
      BCardTitle,
      BLink,
    },

    data() {
      return {
        loading: false,
        data: [],
      };
    },

    methods: {
      getColor(color) {
        let warna = "";
        if (color == "primary") {
          warna = "blue";
        } else if (color == "success") {
          warna = "green";
        } else if (color == "danger") {
          warna = "red";
        } else if (color == "info") {
          warna = "light-blue";
        } else if (color == "warning") {
          warna = "yellow";
        }
        return warna;
      },

      getData() {
        this.loading = true;
        let params = {
          orderCol: "price",
          order: "asc",
        };
        this.$store
          .dispatch("paketTryout/index", params)
          .then((res) => {
            this.loading = false;
            this.data = res.data.data.slice(0, 4);
          })
          .catch((err) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error `,
                icon: "XIcon",
                variant: "danger",
                text: err.response.data.message,
              },
            });
          });
      },

      detail(item) {
        window.location = `paket-detail/${item.slug}`;
      },

      handleScrollAnimation(entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-up');
            entry.target.classList.remove('fade-down');
          } else {
            entry.target.classList.add('fade-down');
            entry.target.classList.remove('fade-up');
          }
        });
      },

      setupIntersectionObserver() {
        const observer = new IntersectionObserver(this.handleScrollAnimation, {
          threshold: 0.1
        });

        document.querySelectorAll('.fade-in').forEach(element => {
          observer.observe(element);
        });
      }
    },

    mounted() {
      this.getData();
      this.setupIntersectionObserver();
    }
  };
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

  * {
    font-family: "Open Sans", sans-serif;
  }

  .fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }

  .fade-up {
    opacity: 1;
    transform: translateY(0);
  }

  .fade-down {
    opacity: 0;
    transform: translateY(20px);
  }

  .custom-image {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
  }

  .image-side .custom-image:hover {
    transform: scale(1.05);
  }

  .text-side div {
    transition: transform 0.3s ease-in-out;
  }

  .text-side:hover div {
    transform: translateX(10px);
  }
</style>