<template>
  <div class="">
    <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000; margin-bottom: 20px"
      fade
      indicators
    >
      <a v-for="(item, i) in banner" :key="i" :href="item.link" target="_blank">
        <b-carousel-slide
          style="object-fit: cover"
          :img-src="apiFile + item.image"
        >
        </b-carousel-slide>
      </a>
    </b-carousel>
  </div>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BCardText,
  BCardTitle,
  BButton,
  BImg,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "animate.css";

export default {
  components: {
    BCard,
    BImg,
    BCarousel,
    BCarouselSlide,
    BCardText,
    BCardTitle,
    BButton,
    BOverlay,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      banner: null,
      bannerData: null,
    };
  },
  methods: {
    async getBanner() {
      this.loading = true;
      let params = {
        type: "slide",
      };
      this.$store
        .dispatch("banner/publicIndex", params)
        .then((res) => {
          this.loading = false;
          this.banner = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
        });
    },
    customImage() {
      this.$swal({
        // title: "ELP",
        html: this.bannerData?.caption,
        // eslint-disable-next-line global-require
        imageUrl: this.apiFile + "/" + this.bannerData?.image,
        imageWidth: 400,
        // imageHeight: auto,
        imageAlt: "ELP",
        showCancelButton: true,
        confirmButtonText: "Dapatkan Promo !",
        cancelButtonText: "Nanti dulu",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          window.open(this.bannerData.link);
          con;
        }
      });
    },
    async getPop() {
      this.loading = true;
      let params = {
        type: "popup",
      };

      this.$store
        .dispatch("banner/publicIndex", params)
        .then((res) => {
          this.loading = false;
          let popup = res.data;
          if (popup.data?.length > 0) {
            this.bannerData = res.data.data[0];
            this.customImage();
          }
        })
        .catch((err) => {
          this.loading = false;
          this.displayError(err);
        });
    },
  },
  created() {
    this.getBanner();
    this.getPop();
  },
};
</script>
<style>
.mulai-sekarang {
  margin-bottom: 100px;
}
.container {
  position: relative;
  text-align: center;
  color: white;
  max-width: 1440px;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
