var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "my-4 d-block d-md-none"
  }, [_c('div', {
    staticClass: "paragraph-1 mb-2"
  }, [_vm._m(0), _c('p', {
    staticClass: "custom-paragraph custom-paragraph-xl text-black text-center"
  }, [_c('b', [_vm._v("Harus Ikut Tryout di " + _vm._s(_vm.appShortName))])])]), _c('div', {
    staticClass: "paragraph-2 mb-2"
  }, [_vm._m(1), _c('p', {
    staticClass: "custom-paragraph-xl text-center mb-1"
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.appName))])]), _c('p', {
    staticClass: "custom-paragraph-md text-center text-dark mb-3",
    staticStyle: {
      "line-height": "2rem"
    }
  }, [_vm._v(" Fasilitas Dahsyat Program " + _vm._s(_vm.appName) + " Untuk Mencapai Target Akademikmu ")])])]), _c('div', {
    staticClass: "d-none d-md-block",
    staticStyle: {
      "margin-top": "100px",
      "margin-bottom": "100px"
    }
  }, [_c('h3', {
    staticClass: "text-dark mb-2",
    attrs: {
      "align": "center"
    }
  }, [_c('b', [_vm._v("Alasan Kenapa Kamu Harus Ikut Tryout di " + _vm._s(_vm.appShortName))])]), _c('h3', {
    staticClass: "text-primary",
    attrs: {
      "align": "center"
    }
  }, [_c('b', [_vm._v("Program Unggulan " + _vm._s(_vm.appName))])]), _c('div', {
    staticClass: "mb-2 text-dark",
    staticStyle: {
      "/* body/18 Reguler */\n\n        font-family": "'General Sans'",
      "font-style": "normal",
      "font-weight": "400",
      "font-size": "18px",
      "line-height": "27px",
      "/* identical to box height, or 150% */\n\n        display": "flex",
      "align-items": "center",
      "/* text/secondary */\n\n        color": "#5a5a5a",
      "/* Inside auto layout */\n\n        flex": "none",
      "order": "1",
      "flex-grow": "0"
    },
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" Fasilitas Dahsyat Program " + _vm._s(_vm.appName) + " Untuk Mencapai Target Akademikmu ")])]), _c('b-row', _vm._l(_vm.advantages, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "3"
      }
    }, [_c('b-card', {
      staticClass: "mb-2",
      style: item.styleCard,
      attrs: {
        "text-variant": "#fffff"
      }
    }, [_c('b-card-text', [_c('feather-icon', {
      staticClass: "mb-1 float-left",
      style: item.styleIcon,
      attrs: {
        "size": "24",
        "icon": item.icon
      }
    }), _c('br'), _c('h5', {
      staticClass: "text-left my-2",
      staticStyle: {
        "color": "black"
      }
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(item.nameHtml)
      }
    })]), _c('section', {
      staticClass: "text-left"
    }, [_c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "outline-secondary"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.viewAdvantage(item);
        }
      }
    }, [_vm._v(" Selengkapnya ")])], 1)], 1)], 1)], 1);
  }), 1), _c('b-modal', {
    attrs: {
      "id": "modal-view-advantage",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$bvModal.hide('modal-view-advantage');
            }
          }
        }, [_vm._v("Tutup")])];
      },
      proxy: true
    }])
  }, [_vm.currentContent == 'program_kedokteran' ? _c('section', [_c('h3', {
    staticClass: "text-primary mb-1"
  }, [_c('strong', [_vm._v("Fasilitas dan Keunggulan Program Kedokteran")])]), _c('ol', {
    staticClass: "mb-2"
  }, [_c('li', [_vm._v("All in program 1 tahun ajaran")]), _c('li', [_vm._v("Konsultasi strategi pilihan jurusan")]), _c('li', [_vm._v("Pengajar profesional dan kompeten di bidangnya")]), _c('li', [_vm._v("Ruang belajar ber-AC dan free hotspot area")]), _c('li', [_vm._v("Tes IQ & minat bakat")]), _c('li', [_vm._v("BTS (diskusi PR / Tugas)")]), _c('li', [_vm._v("Modul & materi lengkap sesuai yang diuji")]), _c('li', [_vm._v("1 kelas 8 orang")]), _c('li', [_vm._v("Semester 1 (6x pertemuan /minggu)")]), _c('li', [_vm._v("Semester 2 (8x pertemuan /minggu)")])]), _c('h3', {
    staticClass: "text-primary mb-1"
  }, [_vm._v("Materi Pelajaran")]), _c('b-row', {
    staticClass: "align-items-start"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Materi Sekolah (Persiapan UH, PTS & PAS)")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "CheckSquareIcon",
      "size": "24"
    }
  }), _c('p', [_vm._v(" Matematik, Kimia, Fisika, Biologi, Bahasa Indonesia, Bahasa Inggris ")])], 1)]), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('strong', {
    staticClass: "d-block"
  }, [_vm._v("Materi UTBK (Persiapan SNBT)")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "CheckSquareIcon",
      "size": "24"
    }
  }), _c('p', [_vm._v("TPS (PU, PBM, PPU & PK)")])], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "CheckSquareIcon",
      "size": "24"
    }
  }), _c('p', [_vm._v("Literasi B. Indonesia & B.Inggris")])], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "CheckSquareIcon",
      "size": "24"
    }
  }), _c('p', [_vm._v("Penalaran Matematika")])], 1)])], 1)], 1) : _vm._e(), _vm.currentContent == 'program_kedinasan' ? _c('section', [_c('h3', {
    staticClass: "text-primary mb-1"
  }, [_c('strong', [_vm._v("Fasilitas Program Kedinasan")])]), _c('h5', {
    staticClass: "text-primary mb-1"
  }, [_vm._v("Fasilitas Kedinasan:")]), _c('ol', {
    staticClass: "mb-2"
  }, [_c('li', [_vm._v("Seluruh Materi SKD")]), _c('ul', [_c('li', [_vm._v("TWK (Tes Wawasan Kebangsaan)")]), _c('li', [_vm._v("TIU (Tes Intelegensi Umum)")]), _c('li', [_vm._v("TKP (Tes Karakteristik Pribadi)")])]), _c('li', [_vm._v("Latihan Samapta / Jasmani / Fisik")]), _c('li', [_vm._v("Mentor Berpengalaman")]), _c('li', [_vm._v("Tempat Nyaman, Ruangan ber-AC")])]), _c('h5', {
    staticClass: "text-primary mb-1"
  }, [_vm._v("Fasilitas Polri:")]), _c('ol', {
    staticClass: "mb-2"
  }, [_c('li', [_vm._v("Seluruh Materi TPA")]), _c('ul', [_c('li', [_vm._v("PU (Tes Pengetahuan Umum)")]), _c('li', [_vm._v("Tes Pengetahuan Polri")]), _c('li', [_vm._v("Matematika, Bhs. Inggris, Bhs. Indonesia, Kewarganegaraan")]), _c('li', [_vm._v("TKP (Tes Karakteristik Pribadi)")])]), _c('li', [_vm._v("Latihan Samapta / Jasmani / Fisik")]), _c('li', [_vm._v("Mentor Berpengalaman")]), _c('li', [_vm._v("Tempat Nyaman, Ruangan ber-AC")])])]) : _vm._e(), _vm.currentContent == 'fasilitas_program' ? _c('section', [_c('h3', {
    staticClass: "text-primary mb-1"
  }, [_c('strong', [_vm._v("Fasilitas Program Super Intensive UTBK dan Karantina")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h4', {
    staticClass: "text-dark"
  }, [_c('strong', [_vm._v("Program Super Intensive UTBK")])]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_vm._v("6 Pertemuan /minggu")]), _c('li', [_vm._v("Belajar sampai H-1 tes UTBK 2024")]), _c('li', [_vm._v("Modul Lengkap Sesuai Materi Uji SNBT 2024")]), _c('li', [_vm._v("Seminar dan Konsultasi Jurusan PTN")]), _c('li', [_vm._v("TRYOUT Progresif Mirip SNBT")]), _c('li', [_vm._v("1 Kelas Maksimal 15 Orang")])])]), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h4', {
    staticClass: "text-dark"
  }, [_c('strong', [_vm._v("Program Karantina")])]), _c('ul', {
    attrs: {
      "type": "circle"
    }
  }, [_c('li', [_vm._v(" 18 Pertemuan /minggu (total pertemuan: 72 KBM, 36x Privat) ")]), _c('li', [_vm._v(" 3 Pertemuan /hari (2x KBBM Grup, 1x Privat 2 orang, 1 Tutor) ")]), _c('li', [_vm._v("1 Kelas Maksimal 10 orang")]), _c('li', [_vm._v("Lama Belajar (awal April sampai dengan Pertengahan Mei)")]), _c('li', [_vm._v("Tryout Setiap Hari")]), _c('li', [_vm._v("TRYOUT Progresif mirip LTMPT (BP3)")]), _c('li', [_vm._v("Modul Lengkap")]), _c('li', [_vm._v("Konsultasi dan Pendampingan")]), _c('li', [_vm._v("Seminar Morivasi")])])])], 1)], 1) : _vm._e(), _vm.currentContent == 'program_smp' ? _c('section', [_c('h3', {
    staticClass: "text-primary mb-1"
  }, [_c('strong', [_vm._v("Program Belajar Kelas 7,8, dan 9 SMP")])]), _c('p', {
    staticClass: "text-dark mb-2"
  }, [_vm._v(" Dirancang untuk meningkatkan nilai RAPOR dengan cara membantu siswa belajar sesuai bahan ajar harian di sekolah. Program dilengkapi dengan persiapan ulangan harian, PTS, PAS dan AKM agar siswa bisa menghadapi ujian. ")]), _c('h4', {
    staticClass: "text-primary mb-1"
  }, [_c('strong', [_vm._v("Keunggulan EINSTEIN")])]), _c('ol', [_c('li', [_vm._v(" Pengajar kompeten dan berpengalaman (pengajar lulusan PTN terbaik dan mengajar dilengkapi dengan trik cepat (the best) dalam mengerjakan soal) ")]), _c('li', [_vm._v("Ruang belajar nyaman, ber-AC dan berkarpet")]), _c('li', [_vm._v("Modul belajar lengkap")]), _c('li', [_vm._v(" Konsultasi jurusan (agar siswa tau strategi yang tepat dalam pemilihan jurusan sesuai minat dan bakat) ")]), _c('li', [_vm._v("Seminar Motivasi dan Info PTN")]), _c('li', [_vm._v(" Tes Minat Bakat (untuk mengetahui minat seseorang terhadap suatu pekerjaan, sebagai bahan pertimbangan ketika memilih jurusan perkualiahan) ")]), _c('li', [_vm._v("Tryout CAT / ONLINE")]), _c('li', [_vm._v(" TST / BTS (Best Time Service) : Jadwal tambahan sesuai request siswa ")]), _c('li', [_vm._v("GRATIS belajar speaking")]), _c('li', [_vm._v("Pertemuan seminggu 4 kali / 90 menit per-pertemuan")])])]) : _vm._e()])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "custom-paragraph custom-paragraph-xl text-black text-center mb-1"
  }, [_c('b', [_vm._v("Alasan Kenapa Kami")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "custom-paragraph custom-paragraph-lg text-primary text-center mb-1"
  }, [_c('b', [_vm._v("Program Unggulan")])]);
}]

export { render, staticRenderFns }